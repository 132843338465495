<script>
import VideoPlayer from '@/components/mentor/materials/VideoPlayer'
import FileCard from '@/components/mentor/materials/FileCard'

export default {
  props: ['content'],
  setup (props) {
    const payload = computed(() => {
      if (typeof props.content === 'string' && props.content[0] === '{') {
        try {
          return JSON.parse(props.content)
        } catch {}
      }
      return props.content
    })

    return {
      payload
    }
  },
  render () {
    if (typeof this.payload !== 'object' || this.payload === null) {
      return h('p', html(this.payload))
    }
    return this.payload.blocks.map(block => {
      switch (block.type) {
        case 'header':
          return h('h' + block.data.level, html(block.data.text))
        case 'paragraph':
          return h('p', html(block.data.text))
        case 'list':
          return h(block.data.style === 'unordered' ? 'ul' : 'ol', block.data.items.map(item => (
            h('li', html(item))
          )))
        case 'delimiter':
          return h('hr')
        case 'image':
          if (block.data.caption !== '') {
            return h('figure', [
              h('img', { src: block.data.file.url }),
              h('figcaption', html(block.data.caption))
            ])
          }
          return h('img', {
            src: block.data.file.url
          })
        case 'attaches':
          if (['mp4', 'mov', 'm4v'].includes(block.data.file.extension)) {
            return h(VideoPlayer, { videoUrl: block.data.file.url })
          }
          return h(FileCard, {
            file: {
              ...block.data.file,
              filename: block.data.file.name,
              extension: block.data.file.extension.substring(1)
            }
          })
        case 'raw':
          return h('span', html(block.data.html))
      }
    }).filter(Boolean)
  }
}

const html = (innerHTML) => ({
  innerHTML
})
</script>
