<template>
  <div class="video-player">
    <div class="video-player__in">
      <video ref="video" :src="videoUrl" controls></video>
    </div>
  </div>
</template>

<script setup>
import Plyr from 'plyr'

const props = defineProps(['videoUrl'])

const video = ref(null)
const plyr = ref(null)

onMounted(() => {
  plyr.value = new Plyr(video.value, {
    i18n: {
      restart: 'Проиграть заново',
      rewind: 'Перемотать на {seektime}с назад',
      play: 'Смотреть',
      pause: 'Пауза',
      fastForward: 'Перемотать на {seektime}с вперёд',
      seek: 'Перемотка',
      seekLabel: '{currentTime} из {duration}',
      played: 'Played',
      buffered: 'Буферизация',
      currentTime: 'Текущая позиция',
      duration: 'Длительность',
      volume: 'Громкость',
      mute: 'Отключить звук',
      unmute: 'Включить звук',
      enableCaptions: 'Включить субтитры',
      disableCaptions: 'Отключить субтитры',
      download: 'Скачать',
      enterFullscreen: 'На полный экран',
      exitFullscreen: 'Выйти из полного экрана',
      frameTitle: 'Плеер {title}',
      captions: 'Субтитры',
      settings: 'Настройки',
      pip: 'PIP',
      menuBack: 'Перейти в предыдущее меню',
      speed: 'Скорость',
      normal: 'Обычная',
      quality: 'Качество',
      loop: 'Цикл',
      start: 'Начало',
      end: 'Конец',
      all: 'Всё',
      reset: 'Сбросить',
      disabled: 'Выключены',
      enabled: 'Включены',
      advertisement: 'Реклама',
      qualityBadge: {
        2160: '4K',
        1440: 'HD',
        1080: 'HD',
        720: 'HD',
        576: 'SD',
        480: 'SD'
      }
    }
  })
})
</script>

<style lang="scss">
@import 'plyr/dist/plyr.css';

.video-player {
  display: flex;
  margin-bottom: 32px;

  &__in {
    border-radius: 8px;
    overflow: hidden;
  }

  --plyr-color-main: var(--color-accent);
  --plyr-menu-background: var(--color-bg);
  --plyr-menu-color: var(--color-text);
  --plyr-menu-radius: 8px;
  --plyr-menu-arrow-color: var(--color-text-dimmed);
  --plyr-menu-back-border-color: var(--color-separator);
  --plyr-menu-back-border-shadow-color: transparent;

  .plyr__menu__container {
    &::after {
      border-top-color: var(--color-bg);
    }

    > div {
      transition-duration: 0.2s;
    }
  }

  .plyr button:hover {
    opacity: 1;
  }

  video {
    max-height: calc(100vh - 250px);
  }

  .plyr:-webkit-full-screen video {
    max-height: 100%;
  }

  .plyr:-moz-full-screen video {
    max-height: 100%;
  }

  .plyr:fullscreen video {
    max-height: 100%;
  }
}

.video-player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 36, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
